<template>
  <b-modal
    lazy
    centered
    id="coupon-upsert"
    :title="coupon ? $t('coupon.edit_coupon', { id: coupon.id }) : $t('coupon.create_new_coupon')"
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="py-2"
    body-class="py-0"
    footer-class="py-2"
    dialog-class="coupon-upsert-modal"
    @shown="onInit"
    @hidden="onInit"
    ><b-form @submit.prevent="onSubmit" id="coupon-upsert-form" class="row">
      <b-col>
        <b-form-group label-for="coupon-title" label-class="text-sm" class="mb-0">
          <template #label>{{ $t('coupon.coupon_title') }}<span class="text-danger ml-1">*</span></template>
          <b-form-input id="coupon-title" v-model="form.title" type="text" :placeholder="$t('coupon.coupon_title')" required :disabled="viewOnly" />
        </b-form-group>
        <b-form-group label-for="coupon-type" label-class="text-sm" class="d-inline-block w-35 mt-3 mb-0 pr-2">
          <template #label>{{ $t('general.type') }}<span class="text-danger ml-1">*</span></template>
          <b-form-select
            id="coupon-type"
            v-model="form.type"
            :options="[
              { value: 'amount', text: $tc('general.amount', 1) },
              { value: 'rate', text: $t('topup.rate') + '(%)' },
            ]"
            required
            :disabled="viewOnly"
            ><b-form-select-option selected disabled hidden :value="null">{{ $t('input.empty') }}</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group label-for="coupon-currency" label-class="text-sm" class="d-inline-block w-30 mt-3 mb-0">
          <template #label>{{ $t('general.currency') }}<span class="text-danger ml-1">*</span></template>
          <b-form-select
            id="coupon-currency"
            v-model="form.currency_code"
            :options="[
              { value: 'GBP', text: 'GBP' },
              { value: 'MXN', text: 'MXN' },
            ]"
            required
            :disabled="viewOnly" />
        </b-form-group>
        <b-form-group v-if="form.type !== 'rate'" label-for="coupon-val-rate" label-class="text-sm" class="d-inline-block w-35 mt-3 mb-0 pl-2">
          <template #label>{{ $t('coupon.value') }}<span class="text-danger ml-1">*</span></template>
          <b-form-input id="coupon-val-rate" v-model="form.amount" type="number" step="0.0001" min="0" required :disabled="viewOnly" />
        </b-form-group>
        <b-form-group v-else label-for="coupon-val-amt" label-class="text-sm" class="d-inline-block w-35 mt-3 mb-0 pl-2">
          <template #label>{{ $t('coupon.value') + '(%)' }}<span class="text-danger ml-1">*</span></template>
          <b-form-input id="coupon-val-amt" v-model="form.amount" type="number" step="0.01" min="0" max="100" required :disabled="viewOnly" />
        </b-form-group>

        <b-form-group label-for="coupon-descr" label-class="text-sm" class="mt-3 mb-0">
          <template #label>{{ $t('general.description') }}<span class="text-danger ml-1">*</span></template>
          <b-form-textarea id="coupon-descr" v-model="form.description" :placeholder="$t('general.description')" rows="10" max-rows="10" required :disabled="viewOnly" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label-for="coupon-expire" label-class="text-sm" class="d-inline-block w-65 pr-1 mb-0">
          <template #label>{{ $t('coupon.expire_at') }}<span class="text-danger ml-1">*</span></template>
          <b-form-input id="coupon-expire" v-model="form.expired_at" type="datetime-local" :disabled="viewOnly" required :placeholder="$t('input.choose_date')" />
        </b-form-group>
        <b-form-group label-for="coupon-status" label-class="text-sm" class="d-inline-block w-35 pr-1 mb-0">
          <template #label>{{ $t('coupon.active_status') }}<span class="text-danger ml-1">*</span></template>
          <b-form-checkbox id="coupon-status" v-model="form.is_active" :value="1" :unchecked-value="0" switch :disabled="viewOnly" />
        </b-form-group>
        <!-- <b-form-group label-class="font-weight-bold input-label" label-for="coupon-sales" class="d-inline-block w-25 pl-2 mt-3 mb-0">
          <template #label>Include Sales <span class="text-danger ml-1">*</span></template>
          <b-form-checkbox id="coupon-sales" v-model="form.sales_included" :value="1" :unchecked-value="0" switch :disabled="viewOnly" />
        </b-form-group> -->
        <b-form-group :label="$t('coupon.competition_name')" label-for="tags-dropdown" label-class="text-sm" class="mt-3 mb-0">
          <b-form-tags id="tags-dropdown" v-model="form.tags" no-outer-focus :disabled="viewOnly" class="mb-2 px-0">
            <template v-slot="{ tags, disabled, addTag, removeTag }">
              <ul v-if="tags && tags.length > 0" class="list-inline d-inline-block my-2 w-100">
                <li v-for="tag in tags" :key="tag" class="list-inline-item w-100">
                  <b-form-tag @remove="removeCouponTag({ tag, removeTag })" :title="tag" :disabled="disabled" variant="info" class="ml-2 mb-2">{{ tag }}</b-form-tag>
                </li>
              </ul>
              <b-dropdown lazy variant="outline-secondary" block menu-class="comp-options" :disabled="disabled" style="width: calc(100% - 1px)">
                <template #button-content>{{ $t('coupon.choose_comp') }}</template>
                <b-dropdown-form @submit.prevent="() => {}">
                  <b-form-group class="m-0" :disabled="disabled">
                    <b-input-group>
                      <b-form-input v-model="search" type="search" :placeholder="$t('input.search_by_title')" @keyup.enter="onSearch" />
                      <b-input-group-append>
                        <b-button variant="basic" @click="onSearch"><b-icon-search class="mr-1" />{{ $t('action.search') }}</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-dropdown-form>
                <b-dropdown-divider />
                <b-dropdown-text v-if="options.length < 1">{{ $t('coupon.no_data_to_select') }}</b-dropdown-text>
                <b-dropdown-item-button
                  v-else
                  v-for="option in options"
                  :key="`comp-option-${option.id}`"
                  :title="option.title"
                  @click="onOptionClick({ option, addTag })"
                  button-class="w-100 text-truncate"
                  >{{ option.title }}
                </b-dropdown-item-button>
              </b-dropdown>
            </template>
          </b-form-tags>
        </b-form-group>
      </b-col>
    </b-form>
    <div v-if="viewOnly" class="row">
      <b-form-group label-for="coupon-created" label-class="text-sm">
        <template #label>{{ $t('general.created_at') }}</template>
        <b-form-input id="coupon-created" v-model="form.created_at" type="datetime-local" disabled />
      </b-form-group>
      <b-table
        show-empty
        :empty-text="$t('notify.table_no_records')"
        small
        hover
        responsive
        head-variant="light"
        class="m-0 p-0"
        :fields="['id', 'title']"
        :items="form.competitions"
        sort-by="id"
        sort-icon-left />
    </div>
    <template #modal-footer="{ hide }">
      <b-button variant="outline-secondary" @click="hide">{{ $t('action.cancel') }}</b-button>
      <b-button variant="outline-primary" type="submit" form="coupon-upsert-form">{{ $t('action.submit') }}</b-button>
    </template>
  </b-modal>
</template>
<script>
import { formatLocalDateTime, formatUtcDateTime } from '@/utils/dateTimeUtils'
import { mapGetters } from 'vuex'
export default {
  name: 'CouponUpsert',
  props: {
    id: String,
    coupon: Object, // initial value
    getList: Function,
    viewOnly: Boolean,
  },
  data() {
    return {
      form: {
        id: null,
        title: null,
        description: null,
        type: null,
        currency_code: 'GBP',
        amount: 0,
        expire_days: 0,
        expired_at: null,
        is_active: 0,
        sales_included: 0,
        competitions: [],
        competition_ids: [],
        tags: [],
      },
      search: '',
      options: [],
    }
  },
  computed: {
    ...mapGetters({ competitions: 'request/competitionList' }),
    criteria() {
      const tmp = this.search.trim().toLowerCase()
      return tmp
    },
  },
  watch: {
    'coupon.id'(newVal, oldVal) {
      this.onInit()
    },
    'form.competitions'(val) {
      this.form.competition_ids = val?.map((comp) => comp.id) ?? []
    },
    competitions(val) {
      if (val && val.data && val.data.length > 0) {
        this.options = []
        val.data.forEach((x, i) => {
          // Filter out already selected options
          if (this.form.competition_ids.indexOf(x.id) == -1 && (!this.criteria || x.title.toLowerCase().indexOf(this.criteria) > -1)) {
            // this.options.push({ id: id, title: title })
            this.options.push(x)
          }
        })
      } else {
        this.options = []
      }
    },
  },
  methods: {
    onInit() {
      if (this.coupon) {
        this.form = {
          ...this.coupon,
          amount: this.coupon.type === 'rate' ? this.coupon.amount * 100 : this.coupon.amount ?? 0,
          expired_at: formatLocalDateTime(this.coupon.expired_at, 'YYYY-MM-DDTHH:mm'),
          created_at: formatLocalDateTime(this.coupon.created_at, 'YYYY-MM-DDTHH:mm'),
          competition_ids: this.coupon.competitions?.map((comp) => comp.id) ?? [],
          tags: this.coupon.competitions?.map((comp) => comp.title) ?? [],
        }
      } else {
        this.form = {
          id: null,
          title: null,
          description: null,
          type: null,
          currency_code: 'GBP',
          amount: 0,
          expire_days: 0,
          expired_at: null,
          is_active: 0,
          sales_included: 0,
          competitions: [],
          competition_ids: [],
          tags: [],
        }
      }
    },
    onSearch() {
      const tmp = this.search.trim().toLowerCase()
      if (tmp.length > 2) {
        this.$store.dispatch('request/getCompetitionList', { title: tmp })
      }
    },
    onOptionClick({ option, addTag }) {
      addTag(option.title)
      this.form.competitions.push(option)
      this.options = this.options.filter((opt) => opt.id !== option.id)
      this.search = ''
    },
    removeCouponTag({ tag, removeTag }) {
      removeTag(tag)
      this.competitions.data.forEach((comp) => {
        if (comp.title === tag) {
          let index = this.form.competitions.findIndex((y) => comp.id === y.id)
          this.form.competitions.splice(index, 1)
        }
      })
    },
    onSubmit() {
      const params = {
        amount: this.form.type === 'rate' ? this.form.amount / 100 : this.form.amount,
        expired_at: formatUtcDateTime(this.form.expired_at),
        successMsg: this.$t('coupon.upsert_coupon_notice[0]', { action: this.form.id ? this.$t('action.update') : this.$t('action.create') }),
        errorMsg: this.$t('coupon.upsert_coupon_notice[1]', { action: this.form.id ? this.$t('action.update') : this.$t('action.create') }),
      }
      for (const key in this.form) {
        if (['amount', 'expired_at', 'created_at', 'updated_at', 'competitions', 'tags'].indexOf(key) == -1) {
          params[key] = this.form[key]
        }
      }
      this.$store.dispatch('request/upsertCoupon', params).then((res) => {
        if (res.status === 200) {
          this.$bvModal.hide('coupon-upsert') // onInit will be called
          this.getList()
        }
      })
    },
  },
  mounted() {
    this.onInit()
  },
}
</script>
<style lang="scss">
.coupon-upsert-modal {
  width: max-content !important;
  min-width: 42rem !important;
  max-width: 75vw !important;
  .comp-options {
    height: 16rem;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
</style>
